<!-- eslint-disable vue/html-indent -->
<!-- eslint-disable max-len -->
<template>
	<div>
		<template v-if="loading && !dataUpdated">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__primary">
						<div class="user-profile__avatar">
							<img
								v-if="getInvestorProfile.avatar"
								:src="getInvestorProfile.avatar"
								alt="Profile picture"
							/>
							<img v-else src="../../assets/img/profile.png" alt="Investor profile picture" />
						</div>
						<p class="user-profile__name">{{ fullName }}</p>
					</div>

					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Email Address:</p>
							<p id="investorEmail">{{ itemData.email }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Phone Number:</p>
							<p id="investorPhone">{{ itemData.phone }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Status:</p>
							<p>
								<span :class="isActive" id="investorStatus">
									{{ itemData.active === 1 ? 'Verified' : 'Unverified' }}
								</span>
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">DriveWealth KYC Status:</p>
							<p>
								<span :class="driveWealthApproval" id="investorStatus">
									{{ itemData.is_kyc_approved === 1 ? 'Approved' : 'Unapproved' }}
								</span>
							</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">BVN Status:</p>
							<p>
								<span :class="bvnStatus" id="investorStatus">
									{{ itemData.is_bvn_validated === 1 ? 'Verified' : 'Unverified' }}
								</span>
							</p>
						</div>

						<div v-if="itemData.kyc" class="user-profile__item">
							<p class="user-profile__section__title">Kyc:</p>
							<p>
								<span :class="isKyc" id="investorKyc">
									{{ itemData.is_kyc_completed === 1 ? 'Completed' : 'Pending' }}
								</span>
							</p>
						</div>

						<div v-if="!itemData.kyc" class="user-profile__item">
							<p class="user-profile__section__title">Kyc:</p>
							<p :class="isKyc" class="text-white p-1">This Investor has not uploaded Kyc</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Role:</p>
							<p>
								<span :class="role" id="investorRole">
									{{ itemData.admin === 1 ? 'Admin' : 'Investor' }}
								</span>
							</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Edit Profile
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="stock-tab"
								data-toggle="tab"
								href="#stock"
								role="tab"
								aria-controls="stock"
								aria-selected="false"
							>
								Stock
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="wishlist-tab"
								data-toggle="tab"
								href="#wishlist"
								role="tab"
								aria-controls="wishlist"
								aria-selected="false"
							>
								Wishlist
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="wallet-tab"
								data-toggle="tab"
								href="#wallet"
								role="tab"
								aria-controls="wallet"
								aria-selected="false"
							>
								Wallet
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="bank-tab"
								data-toggle="tab"
								href="#bank"
								role="tab"
								aria-controls="bank"
								aria-selected="false"
							>
								Bank
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="kyc-tab"
								data-toggle="tab"
								href="#kyc"
								role="tab"
								aria-controls="kyc"
								aria-selected="false"
							>
								KYC
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="mutualFund-tab"
								data-toggle="tab"
								href="#mutualFund"
								role="tab"
								aria-controls="mutualFund"
								aria-selected="false"
							>
								Mutual Funds
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="audit-tab"
								data-toggle="tab"
								href="#audit"
								role="tab"
								aria-controls="audit"
								aria-selected="false"
							>
								Audit
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="savings-tab"
								data-toggle="tab"
								href="#savings"
								role="tab"
								aria-controls="savings"
								aria-selected="false"
							>
								Savings
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								id="transactions-tab"
								data-toggle="tab"
								href="#transactions"
								role="tab"
								aria-controls="transactions"
								aria-selected="false"
							>
								Transactions
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form method="post" @submit.prevent="update">
									<div class="settings__primary">
										<div class="settings__avatar">
											<img
												v-if="getInvestorProfile.avatar"
												:src="getInvestorProfile.avatar"
												alt="Profile picture"
											/>
											<img v-else src="../../assets/img/profile.png" alt="Investor avatar" />
											<span class="icon icon--edit">
												<label class="avatar__label" for="upload-image">
													<input
														ref="avatar"
														class="avatar__input"
														id="upload-image"
														type="file"
														@change="changeAvatar"
														accept="image/*"
													/>
													<svg
														width="9"
														height="9"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path fill="none" d="M0 0h9v9H0z" />
														<path
															d="M1.125 6.468v1.407h1.406L6.68 
															3.727 5.272 2.321 1.125 6.468zM7.766 
															2.64a.373.373 0 0 0 0-.529l-.877-.878a.373.373 
															0 0 0-.529 0l-.686.687L7.08 3.326l.686-.686z"
															fill="#fff"
														/>
													</svg>
												</label>
											</span>
											<div class="avatar__loader">
												<loader v-if="uploadingAvatar" size="small" />
											</div>
										</div>
										<button
											@click.prevent="verifyInvestor"
											class="btn"
											:class="isVerified"
											v-show="!isSelf"
											type="button"
											data-dismiss="modal"
											aria-label="close"
											name="button"
										>
											{{ itemData.active === 1 ? 'Unverify Investor' : 'Verify Investor' }}
										</button>
										<button
											v-if="itemData.kyc"
											@click.prevent="kycCompleted"
											class="btn"
											:class="isKycCompleted"
											v-show="!isSelf"
											type="button"
											data-dismiss="modal"
											aria-label="close"
											name="button"
										>
											{{ itemData.is_kyc_completed === 1 ? 'Deactivate KYC' : 'Activate KYC' }}
										</button>

										<button
											class="btn"
											type="button"
											:class="makeAdmin"
											@click.prevent="toggleAdminStatus"
											v-bind="{ disabled: loading }"
										>
											Make {{ itemData.admin === 1 ? 'Investor' : 'Admin' }}
										</button>
										<button
											class="btn btn__save"
											type="button"
											name="button"
											@click.prevent="update"
											v-bind="{ disabled: loading }"
										>
											Save
										</button>
									</div>
									<div class="invalid-feedback" v-if="uploadImage">
										{{ errors.avatar }}
									</div>
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="investorFirstname">First Name</label>
													<input
														:class="[{ 'is-invalid': errors.firstname }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.firstname"
														name="firstname"
														type="text"
														class="form-control"
														id="investorFirstname"
													/>
												</div>
												<div class="invalid-feedback" id="firstNameError">
													{{ errors.firstname }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="investorLastname">Last Name</label>
													<input
														:class="[{ 'is-invalid': errors.lastname }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.lastname"
														name="lastname"
														type="text"
														class="form-control"
														id="investorLastname"
													/>
												</div>
												<div class="invalid-feedback" id="lastNameError">
													{{ errors.lastname }}
												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<div class="settings__value">
											<label for="investorEmail">Email Address</label>
											<input
												:class="[{ 'is-invalid': errors.email }]"
												@keydown="clearErrors($event.target.name)"
												v-model="itemData.email"
												name="email"
												type="email"
												class="form-control"
												id="investorEmail"
												disabled="disabled"
												placeholder="Email Address"
											/>
										</div>
										<div class="invalid-feedback" id="emailError">
											{{ errors.email }}
										</div>
									</div>
									<div class="form-group">
										<div class="settings__value">
											<label for="investorPhone">Phone Number</label>
											<input
												:class="[{ 'is-invalid': errors.phone }]"
												@keydown="clearErrors($event.target.name)"
												v-model="itemData.phone"
												name="phone"
												type="phone"
												class="form-control"
												id="investorPhone"
												disabled="disabled"
												placeholder="Phone Number"
											/>
										</div>
										<div class="invalid-feedback">
											{{ errors.phone }}
										</div>
									</div>
								</form>
							</div>
						</div>
						<div
							class="tab-pane fade show investor-profile__tabs__section"
							id="stock"
							role="tabpanel"
							aria-labelledby="stock-tab"
						>
							<div class="settings">
								<section class="main-grid__row contacts contacts--list">
									<div class="contacts--list__wrapper ml-3 mt-3" id="investorStockData">
										<template v-if="getInvestorPortfolios && getInvestorPortfolios.length > 0">
											<div>
												<h6 class="info__name">
													Portfolio Total: {{ getInvestorPortfolios.length }}
												</h6>
											</div>
										</template>
										<div class="contacts--list__item head">
											<div></div>
											<p>Stock</p>
											<p>Amount</p>
											<p>Sector</p>
											<p>Quantity</p>
											<p>Exchange</p>
										</div>
										<div class="contacts__body">
											<template v-if="getInvestorPortfolios && getInvestorPortfolios.length > 0">
												<InvestorPortfolioItem
													v-for="investorPortfolio in getInvestorPortfolios"
													:key="investorPortfolio.id"
													:investorPortfolioData="investorPortfolio"
												/>
											</template>
											<template
												v-else-if="getInvestorPortfolios && getInvestorPortfolios.length === 0"
											>
												<p class="mt-5 pl-5">This investor has no stock yet.</p>
											</template>
											<template v-else>
												<p class="alert alert-danger">
													An error occurred while loading investor's stock, please try again.
												</p>
											</template>
										</div>
									</div>
								</section>
							</div>
						</div>
						<div
							class="tab-pane fade investor-profile__tabs__section"
							id="wishlist"
							role="tabpanel"
							aria-labelledby="wishlist-tab"
						>
							<div class="settings">
								<section class="main-grid__row contacts contacts--list">
									<div class="contacts--list__wrapper ml-3 mt-3 mr-3" id="investorWishlistData">
										<template v-if="getInvestorWishlists && getInvestorWishlists.length > 0">
											<div>
												<h6 class="info__name">
													Wishlist Total: {{ getInvestorWishlists.length }}
												</h6>
											</div>
										</template>
										<div class="contacts--list__item_wishlist head tableHeader mt-3">
											<div></div>
											<p>Stock</p>
											<p>Sector</p>
											<p>Exchange</p>
										</div>
										<div class="contacts__body">
											<template v-if="getInvestorWishlists && getInvestorWishlists.length > 0">
												<InvestorWishlistItem
													v-for="getInvestorWishlist in getInvestorWishlists"
													:key="getInvestorWishlist.id"
													:investorWishlistData="getInvestorWishlist"
												/>
											</template>
											<template
												v-else-if="getInvestorWishlists && getInvestorWishlists.length === 0"
											>
												<p class="mt-5 pl-5">
													This investor has no wishlist yet.
												</p>
											</template>
											<template v-else>
												<p class="alert alert-danger">
													An error occurred while loading investor's wishlist, please try
													again.
												</p>
											</template>
										</div>
									</div>
								</section>
							</div>
						</div>
						<div
							class="tab-pane fade investor-profile__tabs__section"
							id="kyc"
							role="tabpanel"
							aria-labelledby="kyc-tab"
						>
							<div class="settings">
								<div class="p-5" v-if="itemData.kyc">
									<div class="form-row">
										<div class="form-group col-md-6">
											<div class="settings__value">
												<label for="investorFirstname">First Name</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.firstname"
												/>
											</div>
										</div>
										<div class="form-group col-md-6">
											<div class="settings__value">
												<label for="investorLastname">Last Name</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.lastname"
												/>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-6">
											<div class="settings__value">
												<label for="investorEmploymentStatus">Employment Status</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.employment_status"
												/>
											</div>
										</div>
										<div class="form-group col-md-6">
											<div class="settings__value">
												<label for="investorEmploymentPosition">Employment Position</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.employment_position"
												/>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-12">
											<div class="settings__value">
												<label for="investorAddress">Address</label>
												<textarea
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.address"
												/>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorGender">Gender</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.gender"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorMaritalStatus">Marital Status</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.marital_status"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorDateOfBirth">Date of Birth</label>
												<p class="mt-2">
													{{ itemData.kyc.date_of_birth | date }}
												</p>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorPostalCode">Postal Code</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.postal_code"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorCity">City</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.city"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorCountry">Country Code</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.country"
												/>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorCompany">Investor Company</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.company"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorCompanyType">Investor Company Type</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.company_type"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorCompanyCountry">Company Country</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.company_country"
												/>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorInvestmentObjectives">Investment Objectives</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.investment_objectives"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorInvestmentExperience">Investment Experience</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.investment_experience"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorAnnualIncome">Investor Annual Income</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.annual_income"
												/>
											</div>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investornetWorthLiquid">Networth Liquid</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.networth_liquid"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorNetworthTotal">Networth Total</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.networth_total"
												/>
											</div>
										</div>
										<div class="form-group col-md-3">
											<div class="settings__value">
												<label for="investorRiskTolerance">Risk Tolerance</label>
												<input
													disabled="disabled"
													class="form-control"
													v-model="itemData.kyc.risk_tolerance"
												/>
											</div>
										</div>
									</div>
									<div v-if="itemData && itemData.kyc_document && itemData.kyc_document.length > 0">
										<div
											class="form-row mt-4"
											v-for="document in itemData.kyc_document"
											:key="document.id"
										>
											<div class="form-group col-md-12">
												<div class="settings__value d-flex text-uppercase">
													<label for="investorIdType" class="mr-4 font-bold">ID Type: </label>
													{{ document.id_type | removeUnderscore }}
												</div>

												<div class="form-group col-md-12">
													<div class="settings__value">
														<label for="investorIdType">ID IMAGE </label>
														<br />
														<img
															class="kycImage"
															v-if="document.id_url"
															:src="document.id_url"
															alt="Investor file"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-else>
									<p class="p-5">Investor has not filled KYC yet</p>
								</div>
							</div>
						</div>
						<div
							class="tab-pane fade investor-profile__tabs__section"
							id="bank"
							role="tabpanel"
							aria-labelledby="bank-tab"
						>
							<div class="settings">
								<section class="main-grid__row contacts contacts--list">
									<div class="contacts--list__wrapper ml-3 mt-3 mr-3" id="investorBankData">
										<template v-if="getInvestorBanks && getInvestorBanks.length > 0">
											<div>
												<h6 class="info__name">Bank Accounts: {{ getInvestorBanks.length }}</h6>
											</div>
										</template>
										<div class="contacts--list__item_bank head tableHeader mt-3">
											<div></div>
											<p>Bank Name</p>
											<p>Account Number</p>
										</div>
										<div class="contacts__body">
											<template v-if="getInvestorBanks && getInvestorBanks.length > 0">
												<InvestorBankItem
													v-for="getInvestorBank in getInvestorBanks"
													:key="getInvestorBank.id"
													:investorBankData="getInvestorBank"
												/>
											</template>
											<template v-else-if="getInvestorBanks && getInvestorBanks.length === 0">
												<p class="mt-5 pl-5">
													This investor has not bank details added yet.
												</p>
											</template>
											<template v-else>
												<p class="alert alert-danger">
													An error occurred while loading investor's bank, please try again.
												</p>
											</template>
										</div>
									</div>
								</section>
							</div>
						</div>
						<div
							class="tab-pane fade investor-profile__tabs__section"
							id="wallet"
							role="tabpanel"
							aria-labelledby="wallet-tab"
						>
							<div class="settings">
								<section class="main-grid__row contacts contacts--list">
									<strong class="pt-3 pl-3 p-2">Investor Wallet</strong>
									<div class="user-profile__tabs mt-3 ml-3">
										<ul class="nav nav-tabs" id="myTab" role="tablist">
											<li class="nav-item">
												<a
													class="nav-link active"
													id="globalWallet-tab"
													data-toggle="tab"
													href="#globalWallet"
													role="tab"
													aria-controls="globalWallet"
													aria-selected="true"
												>
													Global
												</a>
											</li>
											<li class="nav-item">
												<a
													class="nav-link"
													id="localWallet-tab"
													data-toggle="tab"
													href="#localWallet"
													role="tab"
													aria-controls="localWallet"
													aria-selected="true"
												>
													Local
												</a>
											</li>
											<li class="nav-item">
												<a
													class="nav-link"
													id="localGiftCard-tab"
													data-toggle="tab"
													href="#localGiftCard"
													role="tab"
													aria-controls="localGiftCard"
													aria-selected="true"
												>
													Local Gift Card
												</a>
											</li>
											<li class="nav-item">
												<a
													class="nav-link"
													id="globalGiftCard-tab"
													data-toggle="tab"
													href="#globalGiftCard"
													role="tab"
													aria-controls="globalGiftCard"
													aria-selected="true"
												>
													Global Gift Card
												</a>
											</li>
											<li class="nav-item">
												<a
													class="nav-link"
													id="mutualFundWallet-tab"
													data-toggle="tab"
													href="#mutualFundWallet"
													role="tab"
													aria-controls="mutualFundWallet"
													aria-selected="true"
												>
													Mutual Fund
												</a>
											</li>
											<li class="nav-item">
												<a
													class="nav-link"
													id="stockWallet-tab"
													data-toggle="tab"
													href="#stockWallet"
													role="tab"
													aria-controls="stockWallet"
													aria-selected="true"
												>
													Stock
												</a>
											</li>
										</ul>
									</div>
									<div class="tab-content" id="myTabContent">
										<div
											class="tab-pane fade show active user-profile__tabs__section"
											id="globalWallet"
											role="tabpanel"
											aria-labelledby="globalWallet-tab"
										>
											<div class="settings">
												<div class="ml-3 mt-3">
													<strong>Global Wallet</strong>
													<div class="contacts--list__item head">
														<div></div>
														<p>Balance</p>
													</div>
													<div class="contacts__body">
														<InvestorWalletItem
															v-for="investorWallet in getInvestorWallets.global_wallet"
															:key="investorWallet.id"
															:currency="globalCurrency"
															:investorWalletData="investorWallet"
														/>
													</div>
												</div>
											</div>
										</div>
										<div
											class="tab-pane fade show user-profile__tabs__section"
											id="localWallet"
											role="tabpanel"
											aria-labelledby="localWallet-tab"
										>
											<div class="settings">
												<div class="ml-3 mt-3">
													<strong>Local Wallet</strong>
													<div class="contacts--list__item head">
														<div></div>
														<p>Balance</p>
													</div>
													<div class="contacts__body">
														<template
															v-if="
																getInvestorWallets.local_wallet &&
																	getInvestorWallets.local_wallet.length > 0
															"
														>
															<InvestorWalletItem
																v-for="investorWallet in getInvestorWallets.local_wallet"
																:key="investorWallet.id"
																:currency="localCurrency"
																:investorWalletData="investorWallet"
															/>
														</template>
														<template
															v-else-if="
																getInvestorWallets.local_wallet &&
																	getInvestorWallets.local_wallet.length === 0
															"
														>
															<p class="mt-5 pl-5">
																This investor has no Global Wallet yet.
															</p>
														</template>
														<template v-else>
															<p class="alert alert-danger">
																An error occurred while loading investor's Global
																Wallet, please try again.
															</p>
														</template>
													</div>
												</div>
											</div>
										</div>
										<div
											class="tab-pane fade show user-profile__tabs__section"
											id="localGiftCard"
											role="tabpanel"
											aria-labelledby="localGiftCard-tab"
										>
											<div class="settings">
												<div class="ml-3 mt-3">
													<strong>Local Gift Card Wallet</strong>
													<div class="contacts--list__item head">
														<div></div>
														<p>Balance</p>
													</div>
													<div class="contacts__body">
														<template
															v-if="
																getInvestorWallets.local_gift_card_wallet &&
																	getInvestorWallets.local_gift_card_wallet.length > 0
															"
														>
															<InvestorWalletItem
																v-for="investorWallet in getInvestorWallets.local_gift_card_wallet"
																:key="investorWallet.id"
																:currency="localCurrency"
																:investorWalletData="investorWallet"
															/>
														</template>
														<template
															v-else-if="
																getInvestorWallets.local_gift_card_wallet &&
																	getInvestorWallets.local_gift_card_wallet.length ===
																		0
															"
														>
															<p class="mt-5 pl-5">
																This investor has no Local Gift Card Wallet yet.
															</p>
														</template>
														<template v-else>
															<p class="alert alert-danger">
																An error occurred while loading investor's Local Gift
																Card Wallet, please try again.
															</p>
														</template>
													</div>
												</div>
											</div>
										</div>
										<div
											class="tab-pane fade show user-profile__tabs__section"
											id="globalGiftCard"
											role="tabpanel"
											aria-labelledby="globalGiftCard-tab"
										>
											<div class="settings">
												<div class="ml-3 mt-3">
													<strong>Global Gift Card Wallet</strong>
													<div class="contacts--list__item head">
														<div></div>
														<p>Balance</p>
													</div>
													<div class="contacts__body">
														<template
															v-if="
																getInvestorWallets.global_gift_card_wallet &&
																	getInvestorWallets.global_gift_card_wallet.length >
																		0
															"
														>
															<InvestorWalletItem
																v-for="investorWallet in getInvestorWallets.global_gift_card_wallet"
																:key="investorWallet.id"
																:currency="globalCurrency"
																:investorWalletData="investorWallet"
															/>
														</template>
														<template
															v-else-if="
																getInvestorWallets.global_gift_card_wallet &&
																	getInvestorWallets.global_gift_card_wallet
																		.length === 0
															"
														>
															<p class="mt-5 pl-5">
																This investor has no Global Gift Card Wallet yet.
															</p>
														</template>
														<template v-else>
															<p class="alert alert-danger">
																An error occurred while loading investor's Global Gift
																Card Wallet, please try again.
															</p>
														</template>
													</div>
												</div>
											</div>
										</div>
										<div
											class="tab-pane fade show user-profile__tabs__section"
											id="mutualFundWallet"
											role="tabpanel"
											aria-labelledby="mutualFundWallet-tab"
										>
											<div class="settings">
												<div class="ml-3 mt-3">
													<strong>Mutual Fund Wallet</strong>
													<div class="contacts--list__item head">
														<div></div>
														<p>Local</p>
													</div>
													<div class="contacts__body">
														<template
															v-if="
																getInvestorWallets.mutual_fund_portfolio &&
																	getInvestorWallets.mutual_fund_portfolio.length > 0
															"
														>
															<p></p>
															<p class="pl-5">
																{{
																	getInvestorWallets.mutual_fund_portfolio[0].balance
																		| formatCurrency('NGN')
																}}
															</p>
														</template>
														<template
															v-else-if="
																getInvestorWallets.mutual_fund_portfolio &&
																	getInvestorWallets.mutual_fund_portfolio.length ===
																		0
															"
														>
															<p class="mt-5 pl-5">
																This investor has no Mutual Fund Wallet Yet.
															</p>
														</template>
														<template v-else>
															<p class="alert alert-danger">
																An error occurred while loading investor's Mutual Fund
																Wallet, please try again.
															</p>
														</template>
													</div>
													<div class="contacts--list__item head mt-5">
														<div></div>
														<p>Global</p>
													</div>
													<div class="contacts__body">
														<template
															v-if="
																getInvestorWallets.mutual_fund_portfolio &&
																	getInvestorWallets.mutual_fund_portfolio.length > 1
															"
														>
															<p></p>
															<p class="pl-5">
																{{
																	getInvestorWallets.mutual_fund_portfolio[1].balance
																		| formatCurrency('USD')
																}}
															</p>
														</template>
														<template
															v-else-if="
																getInvestorWallets.mutual_fund_portfolio &&
																	getInvestorWallets.mutual_fund_portfolio.length ===
																		0
															"
														>
															<p class="mt-5 pl-5">
																This investor has no Mutual Fund Wallet Yet.
															</p>
														</template>
														<template v-else>
															<p class="alert alert-danger">
																An error occurred while loading investor's Mutual Fund
																Wallet, please try again.
															</p>
														</template>
													</div>
												</div>
											</div>
										</div>
										<div
											class="tab-pane fade show user-profile__tabs__section"
											id="stockWallet"
											role="tabpanel"
											aria-labelledby="stock_portfolio-tab"
										>
											<div class="settings">
												<div class="ml-3 mt-3">
													<strong>Stock Wallet</strong>
													<div class="contacts--list__item head">
														<div></div>
														<p>Balance</p>
													</div>
													<div class="contacts__body">
														<template
															v-if="
																getInvestorWallets.stock_portfolio &&
																	getInvestorWallets.stock_portfolio.length > 0
															"
														>
															<InvestorWalletItem
																v-for="investorWallet in getInvestorWallets.stock_portfolio"
																:key="investorWallet.id"
																:currency="globalCurrency"
																:investorWalletData="investorWallet"
															/>
														</template>
														<template
															v-else-if="
																getInvestorWallets.stock_portfolio &&
																	getInvestorWallets.stock_portfolio.length === 0
															"
														>
															<p class="mt-5 pl-5">
																This investor has no Stock Wallet yet.
															</p>
														</template>
														<template v-else>
															<p class="alert alert-danger">
																An error occurred while loading investor's Stock Wallet,
																please try again.
															</p>
														</template>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
						<div
							class="tab-pane fade show investor-profile__tabs__section"
							id="audit"
							role="tabpanel"
							aria-labelledby="audit-tab"
						>
							<div class="settings">
								<AuditWrapper />
							</div>
						</div>
						<div
							class="tab-pane fade show investor-profile__tabs__section"
							id="mutualFund"
							role="tabpanel"
							aria-labelledby="mutualFund-tab"
						>
							<div class="settings">
								<MutualFundWrapper />
							</div>
						</div>
						<div
							class="tab-pane fade show investor-profile__tabs__section"
							id="savings"
							role="tabpanel"
							aria-labelledby="savings-tab"
						>
							<div class="settings">
								<SavingWrapper />
							</div>
						</div>
						<div
							class="tab-pane fade show investor-profile__tabs__section"
							id="transactions"
							role="tabpanel"
							aria-labelledby="transactions-tab"
						>
							<div class="settings">
								<TransactionWrapper />
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';
import auth from '../../utils/validations/auth';
import InvestorPortfolioItem from './InvestorPortfolioItem';
import InvestorWalletItem from './InvestorWalletItem';
import InvestorBankItem from './InvestorBankItem';
import InvestorWishlistItem from './InvestorWishlistItem';
import AuditWrapper from './AuditWrapper';
import MutualFundWrapper from './MutualFundWrapper';
import SavingWrapper from './SavingWrapper';
import TransactionWrapper from './TransactionWrapper';

export default {
	name: 'InvestorProfile',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true,
			uploadingAvatar: false,
			selectedDoc: {},
			globalCurrency: 'USD',
			localCurrency: 'NGN',
			offset: 0,
			limit: 10,
			uploadImage: true,
			profileLoaded: false,
			walletLoaded: false,
			wishlistLoaded: false,
			banksLoaded: false,
			auditsLoaded: false,
			portfolioLoaded: false,
			mutualfundsLoaded: false,
			savingsLoaded: false,
			errorMessage: null,
			dataUpdated: false,
			wantToMakeAdmin: false,
			transactionsLoaded: false
		};
	},
	created() {
		this.offset =
			this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;

		axios
			.all([
				this.fetchInvestorProfile(this.$route.params.id),
				this.fetchInvestorWallets(this.$route.params.id),
				this.fetchInvestorWishlists(this.$route.params.id),
				this.fetchInvestorPortfolios(this.$route.params.id),
				this.fetchInvestorBanks(this.$route.params.id),
				this.fetchInvestorAudits([this.limit, this.offset, this.$route.params.id]),
				this.fetchInvestorMutualFunds([this.limit, this.offset, this.$route.params.id]),
				this.fetchSavings([this.$route.params.id]),
				this.fetchInvestorTransactions([this.limit, this.offset, this.$route.params.id])
			])
			.then(
				axios.spread((profile, wallet, wishlist, portfolio, banks, audits, mutualfunds, savings, transactions) => {
					this.profileLoaded = profile;
					this.walletLoaded = wallet;
					this.wishlistLoaded = wishlist;
					this.portfolioLoaded = portfolio;
					this.banksLoaded = banks;
					this.auditsLoaded = audits;
					this.mutualfundsLoaded = mutualfunds;
					this.savingsLoaded = savings;
					this.transactionsLoaded = transactions;
				})
			)
			.catch((error) => {
				this.errorMessage = 'Something went Wrong, Please Try again!';
				return error;
			});
	},
	watch: {
		getStatus(value) {
			if (value !== 'loading') {
				this.loading = false;
			}
			if (value === 'loading') {
				this.dataUpdated = false;
			}
		},
		profileLoaded(newValue) {
			if (newValue) {
				this.itemData = this.getInvestorProfile;
			}
		},
		getInvestorProfile(newValue) {
			this.itemData = newValue;
			this.dataUpdated = true;
		}
	},
	computed: {
		...mapGetters([
			'getStatus',
			'getProfileUpdate',
			'getInvestorProfile',
			'getErrorLog',
			'getInvestorPortfolios',
			'getInvestorWallets',
			'getInvestorWishlists',
			'getInvestorBanks',
			'getTotalInvestorAudits',
			'getInvestorMutualFunds',
			'getInvestorSavings'
		]),
		documents() {
			return this.getInvestorProfile.documents;
		},
		cards() {
			return this.getInvestorProfile.cards;
		},
		fullName() {
			return `${this.itemData.firstname} ${this.itemData.lastname}`;
		},
		isActive() {
			return [this.itemData.active === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'];
		},
		driveWealthApproval() {
			return [
				this.itemData.is_kyc_approved === 1 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'
			];
		},
		bvnStatus() {
			const isInvalidated = this.itemData.is_bvn_validated === 1;
			return isInvalidated ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		},
		isKyc() {
			const kycCompleted = this.itemData.is_kyc_completed === 1;
			return kycCompleted ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger';
		},
		role() {
			return [this.itemData.admin === 0 ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'];
		},
		isVerified() {
			return [this.itemData.active === 1 ? 'btn__danger' : 'btn__save--outline'];
		},
		isKycCompleted() {
			return [this.itemData.is_kyc_completed === 1 ? 'btn__danger' : 'btn__save--outline'];
		},
		makeAdmin() {
			return [this.itemData.admin === 1 ? 'btn__danger' : 'btn__save--outline'];
		},
		isSelf() {
			return this.itemData.id === this.$session.get('id');
		}
	},
	methods: {
		...mapActions([
			'updateInvestor',
			'fetchInvestorProfile',
			'getInvestorDoc',
			'getCard',
			'updateDoc',
			'updateAvatar',
			'fetchInvestorPortfolios',
			'fetchInvestorWallets',
			'fetchInvestorWishlists',
			'fetchInvestorBanks',
			'fetchInvestorAudits',
			'fetchInvestorMutualFunds',
			'updateInvestorKyc',
			'fetchSavings',
			'fetchInvestorTransactions'
		]),
		isApproved(doc) {
			return doc.status === 'Declined' ? 'badge badge-pill badge-danger' : 'badge badge-pill badge-success';
		},
		update() {
			this.$Progress.start();
			const data = this.itemData;
			if (this.wantToMakeAdmin) {
				this.wantToMakeAdmin = false;
				return this.updateInvestor({
					admin: this.itemData.admin,
					id: this.itemData.id
				});
			}
			Object.keys(data).forEach((key) => {
				if (data[key] === null) {
					delete data[key];
				}
			});
			this.validate(this.itemData, auth.update);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				this.$Progress.finish();
			}
			this.updateInvestor(data);
			return true;
		},
		changeAvatar() {
			this.uploadingAvatar = true;
			const img = this.$refs.avatar.files[0];
			const imageSize = 3 * 2 ** 20;
			if (img && img.type.match(/image\/*/)) {
				if (img.size > imageSize) {
					errors.avatar = 'File size greater than 3MB';
					return false;
				}
			}
			const reader = new FileReader();
			reader.onload = (e) => {
				this.avatar = e.target.result;
				this.updateAvatar({ avatar: this.avatar, id: this.$route.params.id });
			};
			reader.readAsDataURL(img);
			this.uploadingAvatar = false;
			return true;
		},
		verifyInvestor() {
			this.itemData.active = this.itemData.active === 0 ? 1 : 0;
			this.uploadImage = false;
			this.update();
		},
		kycCompleted() {
			const isKycCompleted = this.itemData.is_kyc_completed === 0 ? 1 : 0;
			const data = {
				id: this.$route.params.id,
				is_kyc_completed: isKycCompleted
			};
			this.updateInvestorKyc(data);
		},
		toggleAdminStatus() {
			this.wantToMakeAdmin = true;
			this.itemData.admin = this.itemData.admin === 0 ? 1 : 0;
			this.update();
		},
		updateDocument(data) {
			this.updateDoc({
				status: data[0],
				id: this.$route.params.id,
				docId: data[1]
			});
		},
		clearDoc() {
			this.selectedDoc = {};
			return true;
		}
	},
	components: {
		Loader,
		InvestorPortfolioItem,
		InvestorWalletItem,
		InvestorBankItem,
		InvestorWishlistItem,
		AuditWrapper,
		MutualFundWrapper,
		SavingWrapper,
		TransactionWrapper
	},
	filters: {
		date(val) {
			return moment(val).format('MMM Do YY');
		},
		removeUnderscore(val) {
			return val.replace(/_/g, ' ');
		}
	}
};
</script>

<style lang="scss" scoped>
.activity__item {
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-gap: 25px;
	margin-bottom: 22px;
}
.settings {
	max-width: 100%;
	margin-right: 1rem;
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
}

.avatar {
	&__input {
		display: none;
	}
	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		&:hover {
			cursor: pointer;
		}
	}
	&__loader {
		position: absolute;
		top: 7px;
		right: -45px;
	}
}
h3 {
	padding-left: 6px;
}
.card {
	border-style: none;
}
.doc {
	&__wrapper {
		.text {
			margin-bottom: 15px;
		}
		margin-bottom: 50px;
	}
	&__img {
		width: 215px;
		height: 150px;
		object-fit: fill;
		object-position: center;
		border-radius: 4px;
		&--lg {
			width: 100%;
			height: auto;
		}
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		margin: 4px;
		padding: 4px;
	}
}
.bold {
	font-weight: bold;
	font-size: 15px;
	margin-right: 5px;
}

.cards {
	padding-top: 2em;
	margin-right: 3em;
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__brand {
		font-weight: bold;
		padding-top: 1rem;
	}
	&__item {
		font-size: 1.5em;
		background: #f1f1f1;
		border: 1px solid #cccccc;
		border-radius: 6px;
		padding: 0.875em 1em;
		max-width: 350px;
		margin-bottom: 2.25rem;
		&:hover {
			.btn-sm {
				visibility: visible;
			}
		}
	}
}

.contacts--list__item {
	display: grid;
	grid-template-columns: 35px 100px 100px 120px 1fr 100px;
}
.contacts--list__item_audit {
	display: grid;
	grid-template-columns: 35px 100px 100px 100px 120px 120px 0.5fr 20px;
}

.contacts--list__item_wishlist {
	display: grid;
	grid-template-columns: 35px 200px 200px 120px 1fr 100px;
}

.contacts--list__item_bank {
	display: grid;
	grid-template-columns: 20px 200px 200px 200px 3fr;
}

.tableHeader {
	background-color: #ffa137;
	padding: 10px;
	color: white;
	border-radius: 5px;
	text-transform: uppercase;
}

.kycImage {
	width: 70%;
	height: 70%;
}
</style>

<template>
	<div>
		<div class="contacts--list__item">
			<p>{{ savings.name }}</p>
			<p>{{ savings.amount | formatCurrency }}</p>
			<p>{{ savings.balance | formatCurrency }}</p>
			<p>{{ savings.duration }} months</p>
			<p>{{ savings.created_at | date }}</p>
			<p>{{ savings.withdraw_date | date }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorSavingItem',
	props: {
		investorSavingData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			savings: this.investorSavingData,
			itemData: ''
		};
	},
	filters: {
		date(val) {
			return moment(val).format('llll');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
/* .contacts--list__item {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 1fr !important;
} */
.contacts--list__item {
	display: grid;
	grid-template-columns: 80px repeat(5, 1fr);
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>

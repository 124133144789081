<template>
	<div>
		<div class="contacts--list__item">
			<p></p>
			<p>{{ investor.balance | formatCurrency(walletCurrency) }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorWalletItem',
	props: {
		investorWalletData: {
			type: Object,
			default() {
				return {};
			}
		},
		currency: {
			type: String
		}
	},
	data() {
		return {
			investor: this.investorWalletData,
			walletCurrency: this.currency,
			itemData: ''
		};
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 50px 90px 0.9fr 80px 1.2fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>

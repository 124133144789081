<template>
	<section class="main-grid__row contacts contacts--list">
		<div class="contacts--list__wrapper ml-3 mt-3" id="investorPortfolioData">
			<div>
				<h6 class="info__name">
					Investor Transactions
				</h6>
			</div>
			<div class="contacts--list__item_transaction head tableHeader mt-3">
				<p>Title</p>
				<p>Description</p>
				<p>Date</p>
				<p>View More</p>
			</div>
			<div class="contacts__body">
				<template v-if="getInvestorTransactions && getInvestorTransactions.length > 0">
					<InvestorTransactionItem
						v-for="investorTransaction in getInvestorTransactions"
						:key="investorTransaction.id"
						:investorTransactionData="investorTransaction"
					/>
				</template>
				<template v-else-if="getInvestorTransactions && getInvestorTransactions.length === 0">
					<p class="mt-5 pl-5">This investor has no transaction yet.</p>
				</template>
				<template v-else>
					<p class="alert alert-danger">
						An error occurred while loading investor's transactions, please try again.
					</p>
				</template>
			</div>
		</div>
		<Pagination
			:options="{ limit, offset, total: getTotalInvestorTransactions, baseUrl }"
			v-if="getTotalInvestorTransactions > limit"
		/>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import InvestorTransactionItem from './InvestorTransactionItem';
import Pagination from '../../components/Pagination';

export default {
	name: 'TransactionWrapper',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true,
			offset: 0,
			limit: 10,
			total: 0,
			baseUrl: 'investor-profile'
		};
	},
	created() {
		if (this.$route.params.id) {
			this.limit = this.$route.query.limit ? this.$route.query.limit : this.limit;
			this.offset =
				this.$route.query.offset || this.$route.query.offset === 0 ? this.$route.query.offset : this.offset;
			this.fetchInvestorTransactions([this.limit, this.offset, this.$route.params.id]);
		}
	},
	watch: {
		$route() {
			this.limit = this.$route.query.limit ? this.$route.query.limit : 10;
			this.offset = this.$route.query.offset ? this.$route.query.offset : 0;
			this.fetchInvestorTransactions([this.limit, this.offset, this.$route.params.id]);
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getInvestorTransactions', 'getTotalInvestorTransactions'])
	},
	methods: {
		...mapActions(['fetchInvestorTransactions'])
	},
	components: {
		InvestorTransactionItem,
		Pagination
	},
	filters: {
		date(val) {
			return moment(val).format('MMM Do YY');
		}
	}
};
</script>

<style lang="scss" scoped>
.activity__item {
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-gap: 25px;
	margin-bottom: 22px;
}
.settings {
	max-width: 90%;
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
}

.avatar {
	&__input {
		display: none;
	}
	&__label {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;
		&:hover {
			cursor: pointer;
		}
	}
	&__loader {
		position: absolute;
		top: 7px;
		right: -45px;
	}
}
h3 {
	padding-left: 6px;
}
.card {
	border-style: none;
}
.doc {
	&__wrapper {
		.text {
			margin-bottom: 15px;
		}
		margin-bottom: 50px;
	}
	&__img {
		width: 215px;
		height: 150px;
		object-fit: fill;
		object-position: center;
		border-radius: 4px;
		&--lg {
			width: 100%;
			height: auto;
		}
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		margin: 4px;
		padding: 4px;
	}
}
.bold {
	font-weight: bold;
	font-size: 15px;
	margin-right: 5px;
}

.cards {
	padding-top: 2em;
	margin-right: 3em;
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__brand {
		font-weight: bold;
		padding-top: 1rem;
	}
	&__item {
		font-size: 1.5em;
		background: #f1f1f1;
		border: 1px solid #cccccc;
		border-radius: 6px;
		padding: 0.875em 1em;
		max-width: 350px;
		margin-bottom: 2.25rem;
		&:hover {
			.btn-sm {
				visibility: visible;
			}
		}
	}
}

.contacts--list__item_transaction {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 1fr;
}

.tableHeader {
	background-color: #ffa137;
	padding: 10px;
	color: white;
	border-radius: 5px;
	text-transform: uppercase;
}

.kycImage {
	width: 70%;
	height: 70%;
}
</style>

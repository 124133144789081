<template>
	<div>
		<div class="contacts--list__item">
			<div class="contacts__info">
				<div class="contacts__img">
					<img v-if="investor.instrument.logo" :src="investor.instrument.logo" alt="Profile picture" />
					<img v-else src="../../assets/img/profile.png" alt="portfolio picture" />
				</div>
				<div class="info__primary">
					<p class="info__name">{{ investor.instrument.name }}</p>
					<p class="info__email">{{ investor.instrument.symbol }}</p>
				</div>
			</div>
			<p>{{ investor.instrument.sector }}</p>
			<p>{{ investor.instrument.exchange }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorWishlistItem',
	props: {
		investorWishlistData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			investor: this.investorWishlistData,
			itemData: '',
			mapStatus: ''
		};
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 230px 200px 0.7fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>

<template>
	<div>
		<div class="contacts--list__item">
			<p>{{ investor.bank.name }}</p>
			<p>{{ investor.account_number }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorBankItem',
	props: {
		investorBankData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			investor: this.investorBankData,
			itemData: '',
			mapStatus: ''
		};
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 250px 200px 200px 1fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>

<template>
	<div>
		<div class="contacts--list__item">
			<p>{{ investor.title }}</p>
			<p>{{ investor.description }}</p>
			<p>{{ investor.created_at | date }}</p>
			<p>
				<router-link
					:to="{ name: 'audit-detail', params: { id: investor.id } }"
					class="btn btn__primary text-center"
					id="viewProfile"
				>
					View More
				</router-link>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorTransactionItem',
	props: {
		investorTransactionData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			investor: this.investorTransactionData,
			itemData: ''
		};
	},
	computed: {
		fullName() {
			return `${this.investor.user.firstname} ${this.investor.user.lastname}`;
		}
	},
	filters: {
		date(val) {
			return moment(val).format('llll');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 1fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>

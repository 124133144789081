import { render, staticRenderFns } from "./InvestorWalletItem.vue?vue&type=template&id=baeab3d6&scoped=true&"
import script from "./InvestorWalletItem.vue?vue&type=script&lang=js&"
export * from "./InvestorWalletItem.vue?vue&type=script&lang=js&"
import style0 from "./InvestorWalletItem.vue?vue&type=style&index=0&id=baeab3d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baeab3d6",
  null
  
)

export default component.exports
<template>
	<div>
		<div class="contacts--list__item mouse-pointer" @click="$router.push({
						name: 'investorMutualFunds-detail',
						params: {
							id: investor.mutual_fund_id,
							userId: investor.user_id
						}
					})">
			<p>{{ investor.mutualFund.title }}</p>
			<p>{{ investor.amount | formatCurrency(investor.mutualFund.currency) }}</p>
			<p>{{ investor.balance | formatCurrency(investor.mutualFund.currency) }}</p>
			<p v-if="investor.start_date">{{ investor.start_date | date }}</p>
			<p v-else>{{ investor.created_at | date }}</p>
			<p>{{ investor.withdraw_date | date }}</p>
			<p class="text-capitalise">{{ investor.payment }}</p>
			<p>{{ investor.duration }} Months</p>
			<div>
				<p v-if="investor.purchase_cycle" class="text-capitalise">{{ investor.purchase_cycle }}</p>
				<p v-else>One Off</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvestorMutualFundItem',
	props: {
		investorMutualFundData: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			investor: this.investorMutualFundData,
			itemData: ''
		};
	},
	filters: {
		date(val) {
			return moment(val).format('DD-MM-YYYY');
		}
	}
};
</script>

<style scoped>
.badge {
	font-weight: normal;
}
.contacts--list__item {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}
.info__primary {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 10px;
}
.info__email {
	text-transform: lowercase;
}
.contacts__info {
	overflow: hidden;
}
</style>
